@charset "utf-8";

@use 'primary' as primary;

// Update Bulma's global variables
$primary: primary.$primary;
$link: primary.$link;
$info: primary.$info;
$success: primary.$success;
$warning: primary.$warning;
$danger: primary.$danger;

$grey-light: primary.$grey-light;

$size-1: primary.$size-1;
$size-2: primary.$size-2;
$size-3: primary.$size-3;
$size-4: primary.$size-4;
$size-5: primary.$size-5;
$size-6: primary.$size-6;
$size-7: primary.$size-7;


// Update some of Bulma's component variables
$title-color: primary.$primary;

// $text: primary.$primary;
$body-background-color: primary.$beige-lighter;


$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

// Import only what you need from Bulma
@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/base/_all.sass";
@import "bulma/sass/helpers/_all.sass";
@import "bulma/sass/grid/columns.sass";
@import "bulma/sass/elements/box.sass";
@import "bulma/sass/elements/button.sass";
@import "bulma/sass/elements/tag.sass";
@import "bulma/sass/elements/image.sass";
@import "bulma/sass/elements/container.sass";
@import "bulma/sass/elements/title.sass";
@import "bulma/sass/elements/other.sass";
@import "bulma/sass/components/navbar.sass";
@import "bulma/sass/components/message.sass";
@import "bulma/sass/components/modal.sass";
@import "bulma/sass/form/_all.sass";
@import "bulma/sass/layout/section.sass";
@import "bulma/sass/layout/footer.sass";

body {
    box-sizing: border-box;
    min-width: 300px;
    margin: 0;
}

#main-content {
    min-height: 80vh;
}

@media screen and (max-width: $tablet) {
    .section {
        padding: 3rem 1rem;
    }
}

.box .title {
    margin-top: 0.5rem;
}

