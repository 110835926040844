@charset "utf-8";

// Set your brand colors
$navy-blue: #132F57;
$mango-sun-red: #ED2124;
$light-steel: #DFDFE7;
$white-steel: #E2E3E7;
$air: #F2F3F5;
$light-sand: #FDF8E6;
$light-gold: #DFCC91;
$green: #00A324;
$green-blue: #2FA3A0;
$water-blue: #0194B4;
$brown: #757763;
$beige-light: #D0D1CD;
$beige-lighter: #EFF0EB;

// $family-sans-serif: "Nunito", sans-serif;

$primary: $navy-blue;
$info: $green-blue;
$link: $mango-sun-red;
$success: $green;
$warning: $light-gold;
$danger: $mango-sun-red;

$grey-light: $light-steel;
$grey-lighter: $beige-lighter;

$size-1: 2.5rem;
$size-2: 2.15rem;
$size-3: 1.75rem;
$size-4: 1.5rem;
$size-5: 1.25rem;
$size-6: 1rem;
$size-7: 0.75rem;


